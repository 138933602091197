import React, { useState } from "react";
import rpic from "../../../assets/refs/r1.jpg";
import rpic2 from "../../../assets/refs/r2.jpg";
import rpic3 from "../../../assets/refs/r3.jpg";
import rpic4 from "../../../assets/refs/r4.jpg";
import rpic5 from "../../../assets/refs/r5.jpg";
import rpic6 from "../../../assets/refs/r6.jpg";
import rpic7 from "../../../assets/refs/r7.jpg";
import rpic8 from "../../../assets/refs/r8.jpg";
import rpic9 from "../../../assets/refs/r9.jpg";
import rpic10 from "../../../assets/refs/r10.jpg";
import rpic11 from "../../../assets/refs/r11.jpg";
import rpic12 from "../../../assets/refs/r12.jpg";
import rpic13 from "../../../assets/refs/r13.jpg";
import rpic14 from "../../../assets/refs/r14.jpg";
import rpic15 from "../../../assets/refs/r15.jpg";
import rpic16 from "../../../assets/refs/r16.jpg";
import rpic17 from "../../../assets/refs/r17.jpg";
import rpic18 from "../../../assets/refs/r18.jpg";
import rpic19 from "../../../assets/refs/r19.jpg";
import rpic20 from "../../../assets/refs/r20.jpg";
import rpic21 from "../../../assets/refs/r21.jpg";
import rpic22 from "../../../assets/refs/r22.jpg";
import rpic23 from "../../../assets/refs/r23.jpg";
import rpic24 from "../../../assets/refs/r24.jpg";
import rpic25 from "../../../assets/refs/r25.jpg";
import rpic26 from "../../../assets/refs/r26.jpg";
import rpic27 from "../../../assets/refs/r27.jpg";
import rpic28 from "../../../assets/refs/r28.jpg";
import rpic29 from "../../../assets/refs/r29.jpg";
import rpic30 from "../../../assets/refs/r30.jpg";
import rpic31 from "../../../assets/refs/r31.jpg";
import rpic32 from "../../../assets/refs/r32.jpg";
import rpic33 from "../../../assets/refs/r33.jpg";
import rpic34 from "../../../assets/refs/r34.jpg";
import rpic35 from "../../../assets/refs/r35.jpg";
import rpic36 from "../../../assets/refs/r36.jpg";
import rpic37 from "../../../assets/refs/r37.jpg";
import rpic38 from "../../../assets/refs/r38.jpg";
import rpic39 from "../../../assets/refs/r39.jpg";
import rpic40 from "../../../assets/refs/r40.jpg";
import rpic41 from "../../../assets/refs/r41.jpg";
import rpic42 from "../../../assets/refs/r42.jpg";
import rpic43 from "../../../assets/refs/r43.jpg";
import rpic44 from "../../../assets/refs/r44.jpg";
import rpic45 from "../../../assets/refs/r45.jpg";
import rpic46 from "../../../assets/refs/r46.jpg";
import rpic47 from "../../../assets/refs/r47.jpg";
import rpic48 from "../../../assets/refs/r48.jpg";
import rpic49 from "../../../assets/refs/r49.jpg";
import rpic50 from "../../../assets/refs/r50.jpg";
import rpic51 from "../../../assets/refs/r51.jpg";
import rpic52 from "../../../assets/refs/r52.jpg";
import rpic53 from "../../../assets/refs/r53.jpg";
import rpic54 from "../../../assets/refs/r54.jpg";
import rpic55 from "../../../assets/refs/r55.jpg";
import rpic56 from "../../../assets/refs/r56.jpg";
import rpic57 from "../../../assets/refs/r57.jpg";
import rpic58 from "../../../assets/refs/r58.jpg";
import rpic59 from "../../../assets/refs/r59.jpg";
import rpic60 from "../../../assets/refs/r60.jpg";
import rpic61 from "../../../assets/refs/r61.jpg";
import rpic62 from "../../../assets/refs/r62.jpg";
import rpic63 from "../../../assets/refs/r63.jpg";
import rpic64 from "../../../assets/refs/r64.jpg";
import rpic65 from "../../../assets/refs/r65.jpg";
import rpic66 from "../../../assets/refs/r66.jpg";
import rpic67 from "../../../assets/refs/r67.jpg";
import rpic68 from "../../../assets/refs/r68.jpg";
import rpic69 from "../../../assets/refs/r69.jpg";
import rpic70 from "../../../assets/refs/r70.jpg";
import rpic71 from "../../../assets/refs/r71.jpg";
import rpic72 from "../../../assets/refs/r72.jpg";
import rpic73 from "../../../assets/refs/r73.jpg";
import rpic74 from "../../../assets/refs/r74.jpg";
import rpic75 from "../../../assets/refs/r75.jpg";
import rpic76 from "../../../assets/refs/r76.jpg";
import rpic77 from "../../../assets/refs/r77.jpg";
import rpic78 from "../../../assets/refs/r78.jpg";
import rpic79 from "../../../assets/refs/r79.jpg";
import rpic80 from "../../../assets/refs/r80.jpg";
import rpic81 from "../../../assets/refs/r81.jpg";
import rpic82 from "../../../assets/refs/r82.jpg";
import rpic83 from "../../../assets/refs/r83.jpg";
import rpic84 from "../../../assets/refs/r84.jpg";
import rpic85 from "../../../assets/refs/r85.jpg";
import rpic86 from "../../../assets/refs/r86.jpg";
import rpic87 from "../../../assets/refs/r87.jpg";
import rpic88 from "../../../assets/refs/r88.jpg";
import rpic89 from "../../../assets/refs/r89.jpg";
import rpic90 from "../../../assets/refs/r90.jpg";
import rpic91 from "../../../assets/refs/r91.jpg";
import rpic92 from "../../../assets/refs/r92.jpg";
import rpic93 from "../../../assets/refs/r93.jpg";
import rpic94 from "../../../assets/refs/r94.jpg";
import rpic95 from "../../../assets/refs/r95.jpg";
import rpic96 from "../../../assets/refs/r96.jpg";
import rpic97 from "../../../assets/refs/r97.jpg";
import rpic98 from "../../../assets/refs/r98.jpg";
import rpic99 from "../../../assets/refs/r99.jpg";
import rpic100 from "../../../assets/refs/r100.jpg";
import rpic101 from "../../../assets/refs/r101.jpg";
import rpic102 from "../../../assets/refs/r102.jpg";
import rpic103 from "../../../assets/refs/r103.jpg";
import rpic104 from "../../../assets/refs/r104.jpg";
import rpic105 from "../../../assets/refs/r105.jpg";
import rpic106 from "../../../assets/refs/r106.jpg";
import rpic107 from "../../../assets/refs/r107.jpg";
import rpic108 from "../../../assets/refs/r108.jpg";
import rpic109 from "../../../assets/refs/r109.jpg";
import rpic110 from "../../../assets/refs/r110.jpg";
import rpic111 from "../../../assets/refs/r111.jpg";
import rpic112 from "../../../assets/refs/r112.jpg";
import rpic113 from "../../../assets/refs/r113.jpg";
import rpic114 from "../../../assets/refs/r114.jpg";
import rpic115 from "../../../assets/refs/r115.jpg";
import rpic116 from "../../../assets/refs/r116.jpg";
import rpic117 from "../../../assets/refs/r117.jpg";
import rpic118 from "../../../assets/refs/r118.jpg";
import rpic119 from "../../../assets/refs/r119.jpg";
import rpic120 from "../../../assets/refs/r120.jpg";
import rpic121 from "../../../assets/refs/r121.jpg";
import rpic122 from "../../../assets/refs/r122.jpg";
import rpic123 from "../../../assets/refs/r123.jpg";
import rpic124 from "../../../assets/refs/r124.jpg";
import rpic125 from "../../../assets/refs/r125.jpg";
import rpic126 from "../../../assets/refs/r126.jpg";
import rpic127 from "../../../assets/refs/r127.jpg";
import rpic128 from "../../../assets/refs/r128.jpg";
import rpic129 from "../../../assets/refs/r129.jpg";
import rpic130 from "../../../assets/refs/r130.jpg";
import rpic131 from "../../../assets/refs/r131.jpg";
import rpic132 from "../../../assets/refs/r132.jpg";
import rpic133 from "../../../assets/refs/r133.jpg";
import rpic134 from "../../../assets/refs/r134.jpg";
import rpic135 from "../../../assets/refs/r135.jpg";
import rpic136 from "../../../assets/refs/r136.jpg";
import rpic137 from "../../../assets/refs/r137.jpg";
import rpic138 from "../../../assets/refs/r138.jpg";
import rpic139 from "../../../assets/refs/r139.jpg";
import rpic140 from "../../../assets/refs/r140.jpg";
import rpic141 from "../../../assets/refs/r141.jpg";
import rpic142 from "../../../assets/refs/r142.jpg";
import rpic143 from "../../../assets/refs/r143.jpg";
import rpic144 from "../../../assets/refs/r144.jpg";
import rpic145 from "../../../assets/refs/r145.jpg";
import rpic146 from "../../../assets/refs/r146.jpg";
import rpic147 from "../../../assets/refs/r147.jpg";
import rpic148 from "../../../assets/refs/r148.jpg";
import rpic149 from "../../../assets/refs/r149.jpg";
import rpic150 from "../../../assets/refs/r150.jpg";
import rpic151 from "../../../assets/refs/r151.jpg";
import rpic152 from "../../../assets/refs/r152.jpg";
import rpic153 from "../../../assets/refs/r153.jpg";
import rpic154 from "../../../assets/refs/r154.jpg";
import rpic155 from "../../../assets/refs/r155.jpg";
import rpic156 from "../../../assets/refs/r156.jpg";
import rpic157 from "../../../assets/refs/r157.jpg";
import rpic158 from "../../../assets/refs/r158.jpg";
import rpic159 from "../../../assets/refs/r159.jpg";
import rpic160 from "../../../assets/refs/r160.jpg";
import rpic161 from "../../../assets/refs/r161.jpg";
import rpic162 from "../../../assets/refs/r162.jpg";
import rpic163 from "../../../assets/refs/r163.jpg";
import rpic164 from "../../../assets/refs/r164.jpg";
import rpic165 from "../../../assets/refs/r165.jpg";
import rpic166 from "../../../assets/refs/r166.jpg";
import rpic167 from "../../../assets/refs/r167.jpg";
import rpic168 from "../../../assets/refs/r168.jpg";
import rpic169 from "../../../assets/refs/r169.jpg";
import rpic170 from "../../../assets/refs/r170.jpg";
import rpic171 from "../../../assets/refs/r171.jpg";
import rpic172 from "../../../assets/refs/r172.jpg";
import rpic173 from "../../../assets/refs/r173.jpg";
import rpic174 from "../../../assets/refs/r174.jpg";
import rpic175 from "../../../assets/refs/r175.jpg";
import rpic176 from "../../../assets/refs/r176.jpg";
import rpic177 from "../../../assets/refs/r177.jpg";
import rpic178 from "../../../assets/refs/r178.jpg";
import rpic179 from "../../../assets/refs/r179.jpg";
import rpic180 from "../../../assets/refs/r180.jpg";
import rpic181 from "../../../assets/refs/r181.jpg";
import rpic182 from "../../../assets/refs/r182.jpg";
import rpic183 from "../../../assets/refs/r183.jpg";
import rpic184 from "../../../assets/refs/r184.jpg";
import rpic185 from "../../../assets/refs/r185.jpg";
import rpic186 from "../../../assets/refs/r186.jpg";
import rpic187 from "../../../assets/refs/r187.jpg";
import rpic188 from "../../../assets/refs/r188.jpg";
import u1 from "../../../assets/refs/2025/u1.jpg";
import u2 from "../../../assets/refs/2025/u2.jpg";
import u3 from "../../../assets/refs/2025/u3.jpg";
import u4 from "../../../assets/refs/2025/u4.jpg";
import u5 from "../../../assets/refs/2025/u5.jpg";
import u6 from "../../../assets/refs/2025/u6.jpg";
import u7 from "../../../assets/refs/2025/u7.jpg";
import u8 from "../../../assets/refs/2025/u8.jpg";  
import u9 from "../../../assets/refs/2025/u9.jpg";  
import u10 from "../../../assets/refs/2025/u10.jpg";  
import u11 from "../../../assets/refs/2025/u11.jpg";  
import u12 from "../../../assets/refs/2025/u12.jpg";  
import u13 from "../../../assets/refs/2025/u13.jpg";  
import u14 from "../../../assets/refs/2025/u14.jpg";  
import u15 from "../../../assets/refs/2025/u15.jpg";  
import u16 from "../../../assets/refs/2025/u16.jpg";  
import u17 from "../../../assets/refs/2025/u17.jpg";  
import u18 from "../../../assets/refs/2025/u18.jpg";  
import u19 from "../../../assets/refs/2025/u19.jpg";  
import u20 from "../../../assets/refs/2025/u20.jpg";  
import u21 from "../../../assets/refs/2025/u21.jpg";  
import u22 from "../../../assets/refs/2025/u22.jpg";  
import u23 from "../../../assets/refs/2025/u23.jpg";  
import u24 from "../../../assets/refs/2025/u24.jpg";  
import u25 from "../../../assets/refs/2025/u25.jpg";  
import u26 from "../../../assets/refs/2025/u26.jpg";  
import u27 from "../../../assets/refs/2025/u27.jpg";  
import u28 from "../../../assets/refs/2025/u28.jpg";  
import u29 from "../../../assets/refs/2025/u29.jpg";  
import u30 from "../../../assets/refs/2025/u30.jpg";  
import u31 from "../../../assets/refs/2025/u31.jpg";  
import u32 from "../../../assets/refs/2025/u32.jpg";  
import u33 from "../../../assets/refs/2025/u33.jpg";  
import u34 from "../../../assets/refs/2025/u34.jpg";  
import u35 from "../../../assets/refs/2025/u35.jpg";  
import u36 from "../../../assets/refs/2025/u36.jpg";  
import u37 from "../../../assets/refs/2025/u37.jpg";  
import u38 from "../../../assets/refs/2025/u38.jpg";  
import u39 from "../../../assets/refs/2025/u39.jpg";  
import u40 from "../../../assets/refs/2025/u40.jpg";  
import u41 from "../../../assets/refs/2025/u41.jpg";  
import u42 from "../../../assets/refs/2025/u42.jpg";  
import u43 from "../../../assets/refs/2025/u43.jpg";  
import u44 from "../../../assets/refs/2025/u44.jpg";  
import u45 from "../../../assets/refs/2025/u45.jpg";  
import u46 from "../../../assets/refs/2025/u46.jpg";  
import u47 from "../../../assets/refs/2025/u47.jpg";  
import u48 from "../../../assets/refs/2025/u48.jpg";  
import u49 from "../../../assets/refs/2025/u49.jpg";  
import u50 from "../../../assets/refs/2025/u50.jpg";  
import u51 from "../../../assets/refs/2025/u51.jpg";  
import u52 from "../../../assets/refs/2025/u52.jpg";  
import u53 from "../../../assets/refs/2025/u53.jpg";  
import u54 from "../../../assets/refs/2025/u54.jpg";  
import u55 from "../../../assets/refs/2025/u55.jpg";  
import u56 from "../../../assets/refs/2025/u56.jpg";  
import u57 from "../../../assets/refs/2025/u57.jpg";  
import u58 from "../../../assets/refs/2025/u58.jpg";  
import u59 from "../../../assets/refs/2025/u59.jpg";  
import u60 from "../../../assets/refs/2025/u60.jpg";  
import u61 from "../../../assets/refs/2025/u61.jpg";  
import u62 from "../../../assets/refs/2025/u62.jpg";  
import u63 from "../../../assets/refs/2025/u63.jpg";  
import u64 from "../../../assets/refs/2025/u64.jpg";  
import u65 from "../../../assets/refs/2025/u65.jpg";  
import u66 from "../../../assets/refs/2025/u66.jpg";  
import u67 from "../../../assets/refs/2025/u67.jpg";  
import u68 from "../../../assets/refs/2025/u68.jpg";  
import u69 from "../../../assets/refs/2025/u69.jpg";  
import u70 from "../../../assets/refs/2025/u70.jpg";  
import u71 from "../../../assets/refs/2025/u71.jpg";  
import u72 from "../../../assets/refs/2025/u72.jpg";  
import u73 from "../../../assets/refs/2025/u73.jpg";  
import u74 from "../../../assets/refs/2025/u74.jpg";  
import u75 from "../../../assets/refs/2025/u75.jpg";  
import u76 from "../../../assets/refs/2025/u76.jpg";  
import u77 from "../../../assets/refs/2025/u77.jpg";  
import u78 from "../../../assets/refs/2025/u78.jpg";  
import u79 from "../../../assets/refs/2025/u79.jpg";  
import u80 from "../../../assets/refs/2025/u80.jpg";  
import u81 from "../../../assets/refs/2025/u81.jpg";  
import u82 from "../../../assets/refs/2025/u82.jpg";  
import u83 from "../../../assets/refs/2025/u83.jpg";  
import u84 from "../../../assets/refs/2025/u84.jpg";  
import u85 from "../../../assets/refs/2025/u85.jpg";  
import u86 from "../../../assets/refs/2025/u86.jpg";  
import u87 from "../../../assets/refs/2025/u87.jpg";  
import u88 from "../../../assets/refs/2025/u88.jpg";  
import u89 from "../../../assets/refs/2025/u89.jpg";  
import u90 from "../../../assets/refs/2025/u90.jpg";  
import u91 from "../../../assets/refs/2025/u91.jpg";  
import u92 from "../../../assets/refs/2025/u92.jpg";  
import u93 from "../../../assets/refs/2025/u93.jpg";  
import u94 from "../../../assets/refs/2025/u94.jpg";  
import u95 from "../../../assets/refs/2025/u95.jpg";  
import u96 from "../../../assets/refs/2025/u96.jpg";  
import u97 from "../../../assets/refs/2025/u97.jpg";  
import u98 from "../../../assets/refs/2025/u98.jpg";  
import u99 from "../../../assets/refs/2025/u99.jpg";  
import u100 from "../../../assets/refs/2025/u100.jpg";  
import u101 from "../../../assets/refs/2025/u101.jpg";  
import u102 from "../../../assets/refs/2025/u102.jpg";  
import u103 from "../../../assets/refs/2025/u103.jpg";  
import u104 from "../../../assets/refs/2025/u104.jpg";  
import u105 from "../../../assets/refs/2025/u105.jpg";  
import u106 from "../../../assets/refs/2025/u106.jpg";  
import u107 from "../../../assets/refs/2025/u107.jpg";  
import u108 from "../../../assets/refs/2025/u108.jpg";  
import u109 from "../../../assets/refs/2025/u109.jpg";  
import u110 from "../../../assets/refs/2025/u110.jpg";  
import u111 from "../../../assets/refs/2025/u111.jpg";  
import u112 from "../../../assets/refs/2025/u112.jpg";  
import u113 from "../../../assets/refs/2025/u113.jpg";  
import u114 from "../../../assets/refs/2025/u114.jpg";  
import u115 from "../../../assets/refs/2025/u115.jpg";  
import u116 from "../../../assets/refs/2025/u116.jpg";  
import u117 from "../../../assets/refs/2025/u117.jpg";  
import u118 from "../../../assets/refs/2025/u118.jpg";  
import u119 from "../../../assets/refs/2025/u119.jpg";  
import u120 from "../../../assets/refs/2025/u120.jpg";  
import u121 from "../../../assets/refs/2025/u121.jpg";  
import u122 from "../../../assets/refs/2025/u122.jpg";  
import u123 from "../../../assets/refs/2025/u123.jpg";  
import u124 from "../../../assets/refs/2025/u124.jpg";  
import u125 from "../../../assets/refs/2025/u125.jpg";  
import u126 from "../../../assets/refs/2025/u126.jpg";  
import u127 from "../../../assets/refs/2025/u127.jpg";  
import u128 from "../../../assets/refs/2025/u128.jpg";  
import u129 from "../../../assets/refs/2025/u129.jpg";  
import u130 from "../../../assets/refs/2025/u130.jpg";  
import u131 from "../../../assets/refs/2025/u131.jpg";  
import u132 from "../../../assets/refs/2025/u132.jpg";  
import u133 from "../../../assets/refs/2025/u133.jpg";  
import u134 from "../../../assets/refs/2025/u134.jpg";  
import u135 from "../../../assets/refs/2025/u135.jpg";  
import u136 from "../../../assets/refs/2025/u136.jpg";  
import u137 from "../../../assets/refs/2025/u137.jpg";  
import u138 from "../../../assets/refs/2025/u138.jpg";  
import u139 from "../../../assets/refs/2025/u139.jpg";  
import u140 from "../../../assets/refs/2025/u140.jpg";  
import u141 from "../../../assets/refs/2025/u141.jpg";  
import u142 from "../../../assets/refs/2025/u142.jpg";  
import u143 from "../../../assets/refs/2025/u143.jpg";  
import u144 from "../../../assets/refs/2025/u144.jpg";  
import u145 from "../../../assets/refs/2025/u145.jpg";  
import "./styles/refs.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

export default function References() {
  const galleryImages = [
    {
      img: rpic,
    },
    {
      img: rpic2,
    },
    {
      img: rpic4,
    },
    {
      img: rpic5,
    },
    {
      img: rpic6,
    },
    {
      img: rpic7,
    },
    {
      img: rpic8,
    },
    {
      img: rpic9,
    },
    {
      img: rpic10,
    },
    {
      img: rpic11,
    },
    {
      img: rpic12,
    },
    {
      img: rpic13,
    },
    {
      img: rpic14,
    },
    {
      img: rpic15,
    },
    {
      img: rpic16,
    },
    {
      img: rpic17,
    },
    {
      img: rpic18,
    },
    {
      img: rpic19,
    },
    {
      img: rpic20,
    },
    {
      img: rpic21,
    },
    {
      img: rpic22,
    },
    {
      img: rpic23,
    },
    {
      img: rpic24,
    },
    {
      img: rpic25,
    },
    {
      img: rpic26,
    },
    {
      img: rpic27,
    },
    {
      img: rpic28,
    },
    {
      img: rpic29,
    },
    {
      img: rpic30,
    },
    {
      img: rpic31,
    },
    {
      img: rpic32,
    },
    {
      img: rpic33,
    },
    {
      img: rpic34,
    },
    {
      img: rpic35,
    },
    {
      img: rpic36,
    },
    {
      img: rpic37,
    },
    {
      img: rpic38,
    },
    {
      img: rpic39,
    },
    {
      img: rpic40,
    },
    {
      img: rpic41,
    },
    {
      img: rpic42,
    },
    {
      img: rpic43,
    },
    {
      img: rpic44,
    },
    {
      img: rpic45,
    },
    {
      img: rpic46,
    },
    {
      img: rpic47,
    },
    {
      img: rpic48,
    },
    {
      img: rpic49,
    },
    {
      img: rpic50,
    },
    {
      img: rpic51,
    },
    {
      img: rpic52,
    },
    {
      img: rpic53,
    },
    {
      img: rpic54,
    },
    {
      img: rpic55,
    },
    {
      img: rpic56,
    },
    {
      img: rpic57,
    },
    {
      img: rpic58,
    },
    {
      img: rpic59,
    },
    {
      img: rpic60,
    },
    {
      img: rpic61,
    },
    {
      img: rpic62,
    },
    {
      img: rpic63,
    },
    {
      img: rpic64,
    },
    {
      img: rpic65,
    },
    {
      img: rpic66,
    },
    {
      img: rpic67,
    },
    {
      img: rpic68,
    },
    {
      img: rpic69,
    },
    {
      img: rpic70,
    },
    {
      img: rpic71,
    },
    {
      img: rpic72,
    },
    {
      img: rpic73,
    },
    {
      img: rpic74,
    },
    {
      img: rpic75,
    },
    {
      img: rpic76,
    },
    {
      img: rpic77,
    },
    {
      img: rpic78,
    },
    {
      img: rpic79,
    },
    {
      img: rpic80,
    },
    {
      img: rpic81,
    },
    {
      img: rpic82,
    },
    {
      img: rpic83,
    },
    {
      img: rpic84,
    },
    {
      img: rpic85,
    },
    {
      img: rpic86,
    },
    {
      img: rpic87,
    },
    {
      img: rpic88,
    },
    {
      img: rpic89,
    },
    {
      img: rpic90,
    },
    {
      img: rpic91,
    },
    {
      img: rpic92,
    },
    {
      img: rpic93,
    },
    {
      img: rpic94,
    },
    {
      img: rpic95,
    },
    {
      img: rpic96,
    },
    {
      img: rpic97,
    },
    {
      img: rpic98,
    },
    {
      img: rpic99,
    },
    {
      img: rpic100,
    },
    {
      img: rpic101,
    },
    {
      img: rpic102,
    },
    {
      img: rpic103,
    },
    {
      img: rpic104,
    },
    {
      img: rpic105,
    },
    {
      img: rpic106,
    },
    {
      img: rpic107,
    },
    {
      img: rpic108,
    },
    {
      img: rpic109,
    },
    {
      img: rpic110,
    },
    {
      img: rpic111,
    },
    {
      img: rpic112,
    },
    {
      img: rpic113,
    },
    {
      img: rpic114,
    },
    {
      img: rpic115,
    },
    {
      img: rpic116,
    },
    {
      img: rpic117,
    },
    {
      img: rpic118,
    },
    {
      img: rpic119,
    },
    {
      img: rpic120,
    },
    {
      img: rpic121,
    },
    {
      img: rpic122,
    },
    {
      img: rpic123,
    },
    {
      img: rpic124,
    },
    {
      img: rpic125,
    },
    {
      img: rpic126,
    },
    {
      img: rpic127,
    },
    {
      img: rpic128,
    },
    {
      img: rpic129,
    },
    {
      img: rpic130,
    },
    {
      img: rpic131,
    },
    {
      img: rpic132,
    },
    {
      img: rpic133,
    },
    {
      img: rpic134,
    },
    {
      img: rpic135,
    },
    {
      img: rpic136,
    },
    {
      img: rpic137,
    },
    {
      img: rpic138,
    },
    {
      img: rpic139,
    },
    {
      img: rpic140,
    },
    {
      img: rpic141,
    },
    {
      img: rpic142,
    },
    {
      img: rpic143,
    },
    {
      img: rpic144,
    },
    {
      img: rpic145,
    },
    {
      img: rpic146,
    },
    {
      img: rpic147,
    },
    {
      img: rpic148,
    },
    {
      img: rpic149,
    },
    {
      img: rpic150,
    },
    {
      img: rpic151,
    },
    {
      img: rpic152,
    },
    {
      img: rpic153,
    },
    {
      img: rpic154,
    },
    {
      img: rpic155,
    },
    {
      img: rpic156,
    },
    {
      img: rpic157,
    },
    {
      img: rpic158,
    },
    {
      img: rpic159,
    },
    {
      img: rpic160,
    },
    {
      img: rpic161,
    },
    {
      img: rpic162,
    },
    {
      img: rpic163,
    },
    {
      img: rpic164,
    },
    {
      img: rpic165,
    },
    {
      img: rpic166,
    },
    {
      img: rpic167,
    },
    {
      img: rpic168,
    },
    {
      img: rpic169,
    },
    {
      img: rpic170,
    },
    {
      img: rpic171,
    },
    {
      img: rpic172,
    },
    {
      img: rpic173,
    },
    {
      img: rpic174,
    },
    {
      img: rpic175,
    },
    {
      img: rpic176,
    },
    {
      img: rpic177,
    },
    {
      img: rpic178,
    },
    {
      img: rpic179,
    },
    {
      img: rpic180,
    },
    {
      img: rpic181,
    },
    {
      img: rpic182,
    },
    {
      img: rpic183,
    },
    {
      img: rpic184,
    },
    {
      img: rpic185,
    },
    {
      img: rpic186,
    },
    {
      img: rpic187,
    },
    {
      img: rpic188,
    },
    {
      img: u1,
    },
    {
      img: u2,
    },
    {
      img: u3,
    },
    {
      img: u4,
    },
    {
      img: u5,
    },
    {
      img: u6,
    },
    {
      img: u7,
    },
    {
      img: u8,
    },
    {
      img: u9,
    },
    {
      img: u10,
    },
    {
      img: u11,
    },
    {
      img: u12,
    },
    {
      img: u13,
    },
    {
      img: u14,
    },
    {
      img: u15,
    },
    {
      img: u16,
    },
    {
      img: u17,
    },
    {
      img: u18,
    },
    {
      img: u19,
    },
    {
      img: u20,
    },
    {
      img: u21,
    },
    {
      img: u22,
    },
    {
      img: u23,
    },
    {
      img: u24,
    },
    {
      img: u25,
    },
    {
      img: u21,
    },
    {
      img: u22,
    },
    {
      img: u23,
    },
    {
      img: u24,
    },
    {
      img: u25,
    },
    {
      img: u26,
    },
    {
      img: u27,
    },
    {
      img: u28,
    },
    {
      img: u29,
    },
    {
      img: u30,
    },
    {
      img: u31,
    },
    {
      img: u32,
    },
    {
      img: u33,
    },
    {
      img: u34,
    },
    {
      img: u35,
    },
    {
      img: u36,
    },
    {
      img: u37,
    },
    {
      img: u38,
    },
    {
      img: u39,
    },
    {
      img: u40,
    },
    {
      img: u41,
    },
    {
      img: u42,
    },
    {
      img: u43,
    },
    {
      img: u44,
    },
    {
      img: u45,
    },
    {
      img: u46,
    },
    {
      img: u47,
    },
    {
      img: u48,
    },
    {
      img: u49,
    },
    {
      img: u50,
    },
    {
      img: u51,
    },
    {
      img: u52,
    },
    {
      img: u53,
    },
    {
      img: u54,
    },
    {
      img: u55,
    },
    {
      img: u56,
    },
    {
      img: u57,
    },
    {
      img: u58,
    },
    {
      img: u59,
    },
    {
      img: u60,
    },
    {
      img: u61,
    },
    {
      img: u62,
    },
    {
      img: u63,
    },
    {
      img: u64,
    },
    {
      img: u65,
    },
    {
      img: u66,
    },
    {
      img: u67,
    },
    {
      img: u68,
    },
    {
      img: u69,
    },
    {
      img: u70,
    },
    {
      img: u71,
    },
    {
      img: u72,
    },
    {
      img: u73,
    },
    {
      img: u74,
    },
    {
      img: u75,
    },
    {
      img: u76,
    },
    {
      img: u77,
    },
    {
      img: u78,
    },
    {
      img: u79,
    },
    {
      img: u80,
    },
    {
      img: u81,
    },
    {
      img: u82,
    },
    {
      img: u83,
    },
    {
      img: u84,
    },
    {
      img: u85,
    },
    {
      img: u86,
    },
    {
      img: u87,
    },
    {
      img: u88,
    },
    {
      img: u89,
    },
    {
      img: u90,
    },
    {
      img: u91,
    },
    {
      img: u92,
    },
    {
      img: u93,
    },
    {
      img: u94,
    },
    {
      img: u95,
    },
    {
      img: u96,
    },
    {
      img: u97,
    },
    {
      img: u98,
    },
    {
      img: u99,
    },
    {
      img: u100,
    },
    {
      img: u101,
    },
    {
      img: u102,
    },
    {
      img: u103,
    },
    {
      img: u104,
    },
    {
      img: u105,
    },
    {
      img: u106,
    },
    {
      img: u107,
    },
    {
      img: u108,
    },
    {
      img: u109,
    },
    {
      img: u110,
    },
    {
      img: u111,
    },
    {
      img: u112,
    },
    {
      img: u113,
    },
    {
      img: u114,
    },
    {
      img: u115,
    },
    {
      img: u116,
    },
    {
      img: u117,
    },
    {
      img: u118,
    },
    {
      img: u119,
    },
    {
      img: u120,
    },
    {
      img: u121,
    },
    {
      img: u122,
    },
    {
      img: u123,
    },
    {
      img: u124,
    },
    {
      img: u125,
    },
    {
      img: u126,
    },
    {
      img: u127,
    },
    {
      img: u128,
    },
    {
      img: u129,
    },
    {
      img: u130,
    },
    {
      img: u131,
    },
    {
      img: u132,
    },
    {
      img: u133,
    },
    {
      img: u134,
    },
    {
      img: u135,
    },
    {
      img: u136,
    },
    {
      img: u137,
    },
    {
      img: u138,
    },
    {
      img: u139,
    },
    {
      img: u140,
    },
    {
      img: u141,
    },
    {
      img: u142,
    },
    {
      img: u143,
    },
    {
      img: u144,
    },
    {
      img: u145,
    },
    
   
  ];
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(galleryImages.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === galleryImages.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col">
            <h1 className="text-center mb-3">Referenciák</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
          <div>
            {openModal && (
              <div className="sliderWrap">
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="btnClose"
                  onClick={handleCloseModal}
                />
                <FontAwesomeIcon
                  icon={faCircleChevronLeft}
                  className="btnPrev"
                  onClick={prevSlide}
                />
                <FontAwesomeIcon
                  icon={faCircleChevronRight}
                  className="btnNext"
                  onClick={nextSlide}
                />
                <div className="fullScreenImage">
                  <img src={galleryImages[slideNumber].img} alt="" />
                </div>
              </div>
            )}

            {/* <br />
Current slide number:  {slideNumber}
<br />
Total Slides: {galleryImages.length}
<br /><br /> */}

            <div className="galleryWrap">
              {galleryImages &&
                galleryImages.map((slide, index) => {
                  return (
                    <div
                      className="single"
                      key={index}
                      onClick={() => handleOpenModal(index)}
                    >
                      <img src={slide.img} alt="" />
                    </div>
                  );
                })}
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
